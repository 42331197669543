const portfolioProjects = [
    { id: 1, title: 'Space Zoologist', vidsrc: '/homeVideos/ZoologistShort.mp4',
    desc: "An educational simulation created to instruct biomedical students.",
    download: "",
    link: "https://louielab.org/SpaceZoologist/",
    blog: "",
    playstore: ""},
    { id: 2, title: 'Colormancy', vidsrc: '/homeVideos/ColormancyShort.mp4', 
    desc: "An online multiplayer dungeon crawler where you combine and cast different spells to paint the environment.", 
    download: "https://drive.google.com/file/d/1jClQC8__ogLUnRPUQg75MEokJB8H-kUF/view?usp=share_link",
    link: "",
    blog: "",
    playstore: ""},
    { id: 3, title: 'Tropical Fruit Match 3', vidsrc: '/homeVideos/TropicalFruitMatch3Short.mp4',
    desc: "A casual, relaxing match 3 game made over a weekend.",
    download: "",
    link: "",
    blog: "",
    playstore: "https://play.google.com/store/apps/details?id=com.Mandarker.TropicalFruitMatch3&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"},
    { id: 4, title: 'Metatope', vidsrc: '/homeVideos/MetatopeShort.mp4',
    desc: "An AR social media app using a single camera to perform full body tracking for recording and sharing videos.", 
    download: "",
    link: "https://www.metatope.com/",
    blog: "",
    playstore: ""},
    { id: 5, title: 'Nijito', vidsrc: '/homeVideos/NijitoShort.mp4', 
    desc: "A rhythm game where you can switch between several rhythm gamemodes in the middle of a song for higher scores.", 
    download: "https://drive.google.com/file/d/1ttnDMLLJtBymH51C0VruiAwm12uLxiSC/view?usp=share_link",
    link: "",
    blog: "",
    playstore: ""},
    { id: 6, title: 'IAIDO', vidsrc: '/homeVideos/IAIDOShort.mp4',
    desc: "An RPS game made during a game jam to utilize Unreal Engine's graphics pipeline.", 
    download: "https://drive.google.com/file/d/1hKphWCj0RhoB9UQpO8O3Ho43vm_h92VH/view?usp=share_link",
    link: "",
    blog: "",
    playstore: ""},
  ];

export default portfolioProjects;